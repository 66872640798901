import React from "react"

export default () => (
  <div>
    <img
      src={"./hello11c.jpg"}
      alt={"photograph a well-worn Hewlett-Packard HP-11c calculator"}
    />
    <h1>Hello.</h1>
    <p>I'm Brian Jenkins.</p>
    <p>I enjoy programming computers and talking with people.</p>
  </div>
)
